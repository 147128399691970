import React from "react"
import * as S from "./HomepageHero.styles.jsx"
import parse from "html-react-parser"

const HomepageHero = ({
  title,
  content,
  video,
  videoPoster,
  height,
  autoplay,
}) => {
  return (
    <>
      <S.Wrapper style={{ height: height ? height : "100vh" }}>
        <S.HeroBg>
          <S.VideoBg
            autoPlay={autoplay ? "autoplay" : false}
            loop
            muted
            src={video.localFile.publicURL}
            poster={
              videoPoster
                ? videoPoster.localFile.childImageSharp.gatsbyImageData.images
                    .fallback.src
                : false
            }
          />
        </S.HeroBg>

        <div className="copy_container">
          <h1>{parse(title)}</h1>
          <div className="content">{parse(content)}</div>
        </div>
      </S.Wrapper>
    </>
  )
}
export default HomepageHero
